@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  background-color: #f8f9fa;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

h1,
h5 {
  font-weight: 600;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
  line-height: 1.75;
}

a {
  font-weight: 500;
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
}

.container {
  max-width: 1100px;
  padding: 1.5rem;
}

img {
  object-fit: cover;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-center {
  text-align: center !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.btn-primary {
  background-color: #333;
  border-color: #333;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.logo-box {
  width: 35vw;

  @media screen and (max-width: 992px) {
    width: 60vw;
  }
}

.card {
  border: none;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-weight: 600;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.75rem;
}

.card-text {
  font-size: 0.95rem;
  color: #6c757d;
}

footer {
  background-color: #343a40;
  color: #f8f9fa;
}

footer p {
  margin: 0;
  font-size: 0.875rem;
}

footer small {
  color: #adb5bd;
}

footer p,
footer small {
  font-weight: 400;
}
